body{
  /* display: flex;
  justify-content: center; */
  /* padding: 0 10rem 0 10rem; */
}

.App {
  text-align: center;
  overflow-y: hidden;
  /* display: flex;
  justify-content: center; */
  /* padding: 2.75rem 10rem 0 10rem; */
  /* padding: 0 10rem 0 10rem; */
  /* flex-grow: 1; */
  /* width: 100%; */
  /* flex-direction: row;
  height: 287.625rem; */
}

@media only screen and (max-width: 1025px){
  /* .App{
    width: 24rem;

  } */
  
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
