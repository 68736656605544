@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body, html {
    /* width: 90rem; */
    overflow-x: hidden;
    display: flex;
    justify-content: center;
}

.calcyoulator_main_layout{
    /* flex-grow: 1; */
    display: flex;
    flex-direction: column;
    gap: 2.75rem;
    padding: 2.75rem 10rem 0 10rem;
    align-items: center;
    /* width: 90rem; */
    /* overflow-y: hidden; */
}

/* Header */
.calcyoulator_main_header{
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    width: 100%;
    gap: 41.575rem;
    max-width: 64rem;
}

.calcyoulator_main_header_logo_container{
    display: flex;
    align-items: center;
    width: 2.5625rem;
    height: 1.125rem;
    gap: 0.5rem;
}

.calcyoulator_main_how_it_works_logo_container{
    display: flex;
    /* align-items: center; */
    flex-direction: column;
    /* width: 2.5625rem; */
    height: 1.125rem;
    gap: 0.5rem;
    justify-content: center;
}

.calcyoulator_main_header_logo_name{
    /* height: 15px;
    width: auto; */
}
.calcyoulator_main_header_links_container{
    display: flex;
    gap: 1.875rem;
}

.calcyoulator_main_header_links{
    cursor: pointer;
    text-wrap:nowrap;
}

/* Estimated Payments */

.calcyoulator_estimate_payments_container{
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    gap: 8.5625rem;
    flex-grow: 1;
    /* width: 100%; */
}

.calcyoulator_estimate_payments_text_container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5625rem;
    justify-content: center;
}

.calcyoulator_estimate_payments_text_1{
    width: 26.1875rem;
    color: #303950;
    font-family: Figtree;
    font-size: 2rem;
    font-style: normal;
    font-weight: 640;
    line-height: 139.5%; /* 2.79rem */
    letter-spacing: 0.01rem;
    text-align: left;
}

.calcyoulator_estimate_payments_text_2{
    color: #455A64;
    font-family: Figtree;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 441;
    line-height: 139.5%; /* 1.74375rem */
    letter-spacing: 0.00625rem;
    text-align: left;
}

.calcyoulator_estimate_payments_image{
    width: 468px;
    height: 295px;
    border-radius: 0.75rem;
    background: url('../images/car_mountian.png'), lightgray 50% / contain no-repeat;
}

.appli_ui_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}

.appli_ui_container_title{
    color: #303950;
    text-align: center;
    font-family: Figtree;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 640;
    line-height: 139.5%; /* 3.13875rem */
    letter-spacing: 0.01125rem;
}

.appli_ui_container_title_you{
    color: #2EB855;
}

.appli_types_loans{
    display: flex;
    padding: 0.75rem;
    align-items: center;
    gap: 0.625rem;
    border-radius: 1rem;
    background: #FFF;
    box-shadow: 0px 2px 11.2px 0px rgba(0, 0, 0, 0.14);
    justify-content: space-between;
    border: none;
}

.appli_type_loans_selected{
    display: flex;
    padding: 0.75rem 0rem;
    align-items: center;
    flex-direction: column;
    gap: 0.625rem;
    border-radius: 0.75rem;
    background: linear-gradient(1deg, #060A13 -37.33%, #272E41 35.05%, #FBFCFF 99.27%);
    box-shadow: 0px 14px 8px 0px rgba(35, 42, 59, 0.28) inset, 6px 4px 8px 0px rgba(44, 51, 70, 0.69) inset, -6px 4px 8px 0px rgba(39, 46, 64, 0.63) inset;
    color: #FFF;
    text-align: center;
    font-family: Figtree;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 139.5%; /* 1.395rem */
    letter-spacing: 0.005rem;
    width: 7.5rem;
}

.appli_types_loans_buttons{
    width: 7.5rem;
    color: #303950;
    text-align: center;
    font-family: Figtree;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 139.5%; /* 1.395rem */
    letter-spacing: 0.005rem;
}

.appli_calculator_frame{
    width: 55rem;
    height: 41.75rem;
    flex-shrink: 0;
    border-radius: 0.8125rem;
    /* background: #F8FAFB; */
    border: none;
}

.appli_estimate_condition{
    width: 62rem;
    color: #455A64;
    text-align: center;
    font-family: Figtree;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 441;
    line-height: 139.5%; /* 1.04625rem */
    letter-spacing: 0.00375rem;
}

.calcyoulator_how_it_works_title{
    color: #303950;
    text-align: center;
    font-family: Figtree;
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 640;
    line-height: 139.5%; /* 3.13875rem */
    letter-spacing: 0.01125rem;
}

.calcyoulator_how_it_works{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.75rem;
}

.calcyoulator_get_in_touch{
    display: inline-flex;
    /* padding: 2.75rem 0rem; */
    align-items: center;
    gap: 9.3125rem;
}

.calcyoulator_how_it_works_block{
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 3.5rem;
}

.calcyoulator_how_it_works_block_reverse{
    flex-direction: row-reverse;
}
.calcyoulator_how_it_works_block_car_house{
    background: url('../images/house_car.png');
    width: 29.25rem;
    height: 27.5rem;
}

.calcyoulator_how_it_works_block_happy{
    background: url('../images/happy_man.png');
    width: 29.25rem;
    height: 27.5rem;
}

.calcyoulator_how_it_works_block_pondering{
    background: url('../images/pondering_woman.png');
    width: 29.25rem;
    height: 27.5rem;
}
.calcyoulator_how_to_block_title{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 1.5rem;
}

.calcyoulator_how_to_block_title_text{
    color: #303950;
    text-align: center;
    font-family: Figtree;
    font-size: 2rem;
    font-style: normal;
    font-weight: 640;
    line-height: 139.5%; /* 2.79rem */
    letter-spacing: 0.01rem;
}

.calcyoulator_how_it_works_text{
    text-align: left;
    width: 29.25rem;
    color: #455A64;
    font-family: Figtree;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 441;
    line-height: 139.5%; /* 1.56938rem */
    letter-spacing: 0.00563rem;
}

.calcyoulator_about{
    width: 90rem;
    height: 18.5625rem;
    flex-shrink: 0;
    background: #2EB855;
}

.calcyoulator_about_text_container{
    display: inline-flex;
    padding: 2.75rem 0rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    color: #FFF;
    width: 62rem;
}

.calcyoulator_about_title{
    text-align: center;
    font-family: Figtree;
    font-size: 2rem;
    font-style: normal;
    font-weight: 640;
    line-height: 139.5%; /* 2.79rem */
    letter-spacing: 0.01rem;
}

.calcyoulator_about_text{
    font-family: Figtree;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 441;
    line-height: 139.5%; /* 1.74375rem */
    letter-spacing: 0.00625rem;
    text-align: left;
}

.calcyoulator_improvements{
    background: #303950;
}

.calcyoulator_improvements_sub_title{
    display: flex;
    align-items: center;
    gap: 1.125rem;
    text-align: center;
    font-family: Figtree;
    font-size: 2.75rem;
    font-style: normal;
    font-weight: 640;
    line-height: 139.5%; /* 3.83625rem */
    letter-spacing: 0.01375rem;
}

.calcyoulator_improvements_sub_title_text{
    font-size: 1.5rem;
}

.calcyoulator_get_in_touch_text_container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
}

.calcyoulator_how_it_works_sub_text{
    width: 29.25rem;
    color: #455A64;
    font-family: Figtree;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 135.5%; /* 1.52438rem */
    letter-spacing: 0.02813rem;
    text-align: left;
}

.get_in_touch_form{
    display: flex;
    padding: 0.625rem;
    flex-direction: column;
    align-items: center;
    gap: 0.9375rem;
}

.get_in_touch_form_input{
    display: flex;
    padding: 0.75rem;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.75rem;
    background: #F8FAFB;
    width: 20rem;
    box-shadow: none;
    border: none;
}

.get_in_touch_form_text_area{
    display: flex;
    padding: 0.75rem 0.75rem 5.6875rem 0.75rem;
    align-items: center;
    gap: 0.625rem;
    border-radius: 0.75rem;
    background: #F8FAFB;
    width: 20rem;
    border: none;
    resize: none;
    font-family: Figtree;
}

.get_in_touch_form_text_area::placeholder, .get_in_touch_form_text_area::placeholder{
    font-family: Figtree;

}

.get_in_touch_form_submit{
    display: flex;
    width: 21.75rem;
    padding: 1rem 3rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    border-radius: 1.25rem;
    background: #303950;
    color: #FFF;
    cursor: pointer;
}

.calcyoulator_footer{
    width: 90rem;
    height: 8.4375rem;
    flex-shrink: 0;
    background: #F8FAFB;
}

.footer_powered_by{
    color: #455A64;
    font-family: Figtree;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 139.5%; /* 1.395rem */
    letter-spacing: 0.005rem;
}

.calcyoulator_footer_logo_container{
    display: inline-flex;
    padding: 2.75rem 0rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.4375rem;
    color: #FFF;
    width: 38rem;
}

.calcyoulator_footer_container{
    display: inline-flex;
    /* padding: 2.75rem 0rem; */
    flex-direction: row;
    align-items: flex-start;
    gap: 1.5rem;
    color: #FFF;
    width: 62rem;
    align-items: center;
    justify-content: center;
}

.calcyoulator_footer_links_container{
    display: flex;
    gap: 1rem;
    flex-direction: column;
    text-align: left;
}

.calcyoulator_footer_links{
    color: #000;
    font-family: Figtree;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 441;
    line-height: 139.5%; /* 1.56938rem */
    letter-spacing: 0.00563rem;
    text-decoration: none;
}

.calcyoulator_copyright_container{
    background-color: rgb(255, 255, 255);
    height: 2.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.calcyoulator_privacy_container{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 45.8125rem;
    flex-direction: column;
    padding: 5rem 0;
    gap: 2rem
}

.privacy_title_container{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    /* padding: 5rem 0; */
}

.privacy_title{
    color: #303950;
    font-family: Figtree;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 151%; /* 4.53rem */
    letter-spacing: 0.015rem;
}

.privacy_effective{
    color: #303950;
    font-family: Figtree;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 151%;
    letter-spacing: 0.01rem;
    text-align: left;
}

.privacy_sub_text{
    color: #303950;
    font-family: Figtree;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 151%;
    letter-spacing: 0.00563rem;
    text-align: left;
}

.privacy_sub_title{
    color: #303950;
    font-family: Figtree;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 151%;
    letter-spacing: 0.0075rem;
    text-align: left;
}

@media only screen and (max-width: 1025px){
    .calcyoulator_main_header_links_container{
        display: none;
    }

    .calcyoulator_main_layout{
        /* all: unset !important; */
        max-width: 24.375rem;
        /* height: 346.6875rem; */
        gap: 2.69rem;
    }

    .calcyoulator_main_header_logo_container{
        display: flex;
        width: 24.375rem;
        padding: 0.875rem 4.1875rem 0.8125rem 0.8125rem;
        align-items: flex-start;
        gap: 0.55rem;
    }
    .calcyoulator_estimate_payments_container{
        flex-grow: unset;
        flex-direction: column;
        gap: 2.69rem;
    }

    .calcyoulator_estimate_payments_text_container{
        gap: 1rem;
    }
    .calcyoulator_estimate_payments_image{
        width: 22.25rem;
        height: 14.39931rem;
        border-radius: 0.75rem;
        background: url('../images/car_mountian.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    .calcyoulator_estimate_payments_text_1{
        max-width: 22rem;
        font-size: 1.5rem;
    }

    .calcyoulator_estimate_payments_text_2{
        max-width: 22rem;
        font-size: 1.25rem;
    }

    .appli_ui_container_title{
        font-size: 1.75rem;
    }

    .appli_types_loans_buttons{
        width: 6.25rem;
        font-size: 0.875rem
    }

    .appli_calculator_frame{
        width: 24rem;
        height: 46.75rem;
        flex-shrink: 0;
    }

    .appli_estimate_condition{
        font-size: 0.625rem;
        width: 21.25rem;
    }

    .calcyoulator_how_it_works{
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        gap: 2.75rem;
    }

    .calcyoulator_how_it_works_block{
        flex-direction: column;
    }

    .calcyoulator_how_it_works_block_car_house{
        /* background: url('../images/house_car.png'); */
        background: url('../images/house_car.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 21.25rem;
        height: 16.25rem;
        border-radius: 0.75rem;
    }
    
    .calcyoulator_how_it_works_block_happy{
        background: url('../images/happy_man.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 21.25rem;
        height: 16.25rem;
        border-radius: 0.75rem;
    }
    
    .calcyoulator_how_it_works_block_pondering{
        background: url('../images/pondering_woman.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        width: 21.25rem;
        height: 16.25rem;
        border-radius: 0.75rem;
    }

    .calcyoulator_how_it_works_text{
        width: 21.25rem;
    }

    .calcyoulator_main_how_it_works_logo_container{
        height: unset;
    }

    .calcyoulator_about{
        width: 23.375rem;
        height: 27.3125rem;
    }

    .calcyoulator_about_text_container{
        width: 17.125rem;
        gap: 1rem;
        /* width: 19rem; */
    }

    .calcyoulator_improvements_emodicon{
        /* font-size: 2rem; */
    }
    .calcyoulator_improvements_sub_title_text{
        font-size: 1.2rem;
        line-height: 139.5%;
        text-align: left;
    }

    .calcyoulator_get_in_touch{
        display: flex;
        flex-direction: column;
        gap: 1.06rem;
    }

    .calcyoulator_get_in_touch_text_container{
        width: 21.25rem;
        align-items: center;
    }

    .calcyoulator_how_it_works_sub_text{
        width: unset;
    }

    .calcyoulator_main_header_logo_container_on_footer{
        display: flex;
        width: 24.375rem;
        padding: unset;
        align-items: flex-start;
        gap: 0.55rem;
        justify-content: flex-start;
    }

    .calcyoulator_footer_container{
        gap: 0;
        flex-direction: column;
        width: unset;
        align-items: flex-start;
        padding-left: 2rem;
        padding-bottom: 2rem;
    }

    .calcyoulator_footer{
        height: unset;
        width: 22rem;
        align-items: flex-start;
        display: flex;
        overflow-x: hidden;
    }

    .calcyoulator_copyright_container{
        width: 22rem;
    }

    .calcyoulator_privacy_container{
        width: 22rem;
    }
}